<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Determine the net spin for each of the metal atoms/ions below:</p>

      <p class="mb-4 pl-4">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-2 mr-2 pr-0" cols="1">
            <chemical-latex content="a)  Zn" />
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="2">
            <v-select
              v-model="inputs.netSpinZn"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select net spin:"
              dense
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-n3 pl-4">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-2 mr-2 pr-0" cols="1">
            <chemical-latex content="b)  Ni^2+" />
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="2">
            <v-select
              v-model="inputs.netSpinNi"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select net spin:"
              dense
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question479',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        netSpinZn: null,
        netSpinNi: null,
      },
      items: [
        {text: '+5/2', value: '+5/2'},
        {text: '+2', value: '+2'},
        {text: '+3/2', value: '+3/2'},
        {text: '+1', value: '+1'},
        {text: '+1/2', value: '+1/2'},
        {text: '0', value: '0'},
        {text: '-1/2', value: '-1/2'},
        {text: '-1', value: '-1'},
        {text: '-3/2', value: '-3/2'},
        {text: '-2', value: '-2'},
        {text: '-5/2', value: '-5/2'},
      ],
    };
  },
};
</script>
