var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-5"},[_vm._v("Determine the net spin for each of the metal atoms/ions below:")]),_c('p',{staticClass:"mb-4 pl-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex my-0 pb-0 pt-2 mr-2 pr-0",attrs:{"cols":"1"}},[_c('chemical-latex',{attrs:{"content":"a)  Zn"}})],1),_c('v-col',{staticClass:"d-flex my-0 pb-0 pl-0 ml-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select net spin:","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.netSpinZn),callback:function ($$v) {_vm.$set(_vm.inputs, "netSpinZn", $$v)},expression:"inputs.netSpinZn"}})],1)],1)],1),_c('p',{staticClass:"mb-n3 pl-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex my-0 pb-0 pt-2 mr-2 pr-0",attrs:{"cols":"1"}},[_c('chemical-latex',{attrs:{"content":"b)  Ni^2+"}})],1),_c('v-col',{staticClass:"d-flex my-0 pb-0 pl-0 ml-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select net spin:","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.netSpinNi),callback:function ($$v) {_vm.$set(_vm.inputs, "netSpinNi", $$v)},expression:"inputs.netSpinNi"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }